img {
  display: block;
}

.clear {
  clear: both;
}

/******** 导航 *********/
.nav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 990;
}

.nav img {
  margin: 15px 0;
  width: 160px;
}

.fixed_nav {
  width: 100%;
  height: 70px;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(0, 68, 238, 0.1);
}

.static_nav {
  width: 100%;
  height: 70px;
  float: left;
}

.menu {
  height: 70px;
  line-height: 70px;
  position: relative;
}

.menu>div {
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
}

.menu>div img {
  width: 7px;
  height: 4px;
  margin: 33px 5px;
  transition: transform 0.2s linear 0s;
}

.static_nav .menu>div,
.static_nav .menu>div>a {
  color: #fff;
}

.fixed_nav .menu>div,
.fixed_nav .menu>div>a {
  color: #222222;
}

.menu_item::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  top: 0;
  left: 0;
}

.menu>div:nth-child(2):hover img,
.transform:hover img {
  transform: rotate(180deg);
}

.fixed_nav .menu .menu_item::before {
  background-color: #316DF7;
}

.static_nav .menu .menu_item::before {
  background-color: #fff;
}

.applications {
  width: 1200px;
  height: 381px;
  background-color: #fff;
  padding: 0 !important;
  z-index: 998;
  margin: 0 40px;
  box-shadow: 0px 15px 30px rgba(5, 28, 85, 0.1);
}

.ant-popover-inner-content {
  padding: 0 !important;
  box-shadow: 0px 15px 30px rgba(5, 28, 85, 0.1);
  /* background-color: red; */
}

/* .applications > div{
  position: relative;
} */

/* .applications > div > div{
  width: 120px;
} */
.applications .applications_title {
  width: 340px;
  height: 64px;
  line-height: 70px;
  font-size: 14px;
  color: #222;
  border-bottom: 1px solid #ddd;
}

.applications ul {
  width: 340px;
  padding-top: 10px;
}

.applications li {
  width: 340px;
  height: 64px;
  padding: 12px 0 0px 12px;
}

.applications li:hover {
  background-color: #EEF3FF !important;
}

.applications li a {
  color: #606365;
}

.applications li:hover a {
  color: #316DF7 !important;
}

/* 解决方案 start */
.solutions>div {
  position: relative;
}

.solutions>div>div {
  width: 120px;
}

.solutions ul {
  padding-top: 10px;
}

.solutions li {
  height: 40px;
  line-height: 40px;
}

.solutions li a {
  color: #606365;
  padding-left: 20px;
}

.solutions li:hover {
  background-color: #EEF3FF !important;
}

.solutions li:hover a {
  color: #316DF7 !important;
}

/* 解决方案 end */

.btn {
  width: 96px;
  height: 32px;
  margin: 19px 0;
}

.static_nav .btn {
  border-color: #fff;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.btn_color button:hover {
  color: #316DF7 !important;
}

.static_nav .btn:hover,
.static_nav .btn:focus,
.static_nav .btn:active,
.static_nav .btn.active {
  border-color: #fff;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.fixed_nav .btn {
  border-color: #316DF7;
  color: #fff;
  background-color: #316DF7;
}

.fixed_nav .btn:hover,
.fixed_nav .btn:focus,
.fixed_nav .btn.active {
  border-color: #316DF7;
  color: #fff;
  background-color: #316DF7;
}

/******** 导航 *********/
.hover_ul li {
  padding: 23px 15px;
}

.hover_ul li:hover {
  box-shadow: 0 4px 16px rgba(0, 19, 65, 0.15);
}

/******** 底部 *********/
.link {
  color: #AAADB1;
}

.link:hover {
  color: #316DF7;
}

/******** 底部 *********/

/******** 首页 *********/
.header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  border-bottom: 1px solid #e6e6e6;
}

header {
  height: 600px;
  background-size: cover;
}

header .banner {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 600px;
}

.class_item {
  width: 380px;
  height: 420px;
  background-color: #fff;
  margin-bottom: 80px;

}

.class_item>div {
  background-color: #316DF7;
  height: 90px;
}

.class_item button,
.class_item button:hover,
.class_item button:active,
.class_item button:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.class_item .class_app {
  height: 110px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.class_app:hover {
  background-color: #F5F6FA;
}

.class_app:hover .app_btn {
  display: inline-block;
}

.label {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  margin-top: 8px;
  display: inline-block;
  border: 2px solid rgba(49, 109, 247, 1);
  border-radius: 50%;
  box-sizing: border-box;
}

.class_item .app_btn {
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
}

.class_item .app_btn,
.class_item .app_btn:hover,
.class_item .app_btn:active,
.class_item .app_btn:focus {
  color: #316DF7;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 5px rgba(0, 24, 86, 0.2);
  border-radius: 4px;
}

.ant-message {
  top: 200px !important;
  text-align: center;
}

.bg-img {
  height: 220px;
  padding-top: 60px;
  background: url("../../static/images/image_bg220.png") no-repeat center center;
  background-size: cover;
}

.LinkRegister {
  width: 140px;
  height: 40px;
  line-height: 36px;
  display: inline-block;
  border: 1px solid #FFFFFF;
  opacity: 1;
  border-radius: 2px;
  color: #fff;
  padding: 0;
  font-size: 16px;
  margin-top: 30px;
}

.LinkRegister:hover {
  color: #fff;
}

.leave_word {
  height: 368px;
  background: url("../../static/images/image_bg368.png") no-repeat center center;
  background-size: cover;
}

.leave_word .submit {
  width: 140px;
  height: 40px;
  border: 1px solid #FFFFFF;
  border-radius: 2px;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  margin: 20px auto;
}

.plan .plan_menu {
  width: 120px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  font-size: 16px;
  outline: none;
}

.plan .plan_menu:hover,
.plan .plan_menu:active,
.plan .plan_menu:focus {
  border: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
}

.plan .plan_menu.plan_menu_select {
  border: #316DF7;
  background-color: #316DF7;
}

.plan .plan_btn {
  width: 86px;
  height: 36px;
  line-height: 30px;
  padding: 0;
  color: #fff;
  margin: 20px 0 20px 20px;
}

.plan .plan_btn.plan_btn_d,
.plan .plan_btn.plan_btn_d:hover,
.plan .plan_btn.plan_btn_d:active,
.plan .plan_btn.plan_btn_d:focus {
  border: 1px solid #fff;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.plan .plan_btn.plan_btn_r,
.plan .plan_btn.plan_btn_r:hover,
.plan .plan_btn.plan_btn_r:active,
.plan .plan_btn.plan_btn_r:focus {
  border: 1px solid #316DF7;
  color: #fff;
  background-color: #316DF7;
}

.ul_item {
  width: 380px;
  height: 410px;
  padding: 40px 60px;
  background-color: #fff;
  border-top: 4px solid #4049DE;
}

.ul_item_title {
  font-size: 28px;
  text-align: center;
  color: #316DF7;
  margin-bottom: 10px;
}

.ul_item_desc {
  height: 135px;
  font-size: 16px;
  color: #606365;
  line-height: 27px;
}

.ul_item img {
  width: 120px;
  height: 120px;
}

.anchor {
  display: inline-block;
  width: 48px;
  height: 48px;
  background: url("../images/return_top.png") no-repeat left top;
  position: fixed;
  right: 50px;
  bottom: 200px;
}

.anchor:hover {
  background: url("../images/return_top_hover.png") no-repeat left top;
}

.more {
  height: 40px;
  line-height: 40px;
  background: #316DF7;
  border: 1px solid #316DF7;
  border-radius: 2px;
  color: #fff;
  font-size: 16px;
  padding: 0 40px;
  outline: none;
  cursor: pointer;
}

.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 991;
}

.mask_content {
  width: 360px;
  border-radius: 16px;
  position: absolute;
  left: calc((100vw - 360px)/2);
  top: 120px;
  overflow: hidden;
  display: flex;
  flex-flow: wrap;
}

.mask_content .kf {
  width: 360px;
}

.mask_content>div {
  width: 360px;
  border-radius: 0 0 16px 16px;
}

.mask_content>div Input {
  background: #f4f5f6;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
}

.mask_content>div TextArea {
  background: #F1F2F3;
  height: 100px;
  border-radius: 4px;
  margin-top: 20px;
}

.mask_content>div .submit {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #316DF7;
  opacity: 1;
  border-radius: 4px;
  margin-top: 20px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.btn_close {
  padding-top: 30px;
  text-align: center;
}

.btn_close img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.development_process {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #D5DAE5;
  border-bottom: 1px solid #D5DAE5;
}

.development_process li {
  width: 20%;
  height: 330px;
  position: relative;
  padding: 80px 28px;
  border-left: 1px solid #D5DAE5;
  cursor: pointer;
}

.development_process li:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  top: 30px;
  background-color: #fff;
  border-top: 1px solid #D5DAE5;
  border-right: 1px solid #D5DAE5;
  z-index: 111;
  transform: rotate(45deg);
}

.development_process li:last-child:after {
  display: none;
}

.development_process li:hover {
  background-color: #316DF7;
}

.development_process li:hover:after {
  background-color: #316DF7;
  border-color: #316DF7;
}

.development_process li:hover p {
  color: #fff !important;
}

.development_process li:last-child {
  border-right: 1px solid #D5DAE5;
}

.development_process li .serial_number {
  color: #316DF7;
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
}

.development_process li .title {
  margin-top: 32px;
  font-size: 21px;
  font-weight: bold;
  color: #222;
}

.development_process li .desc {
  margin-top: 24px;
  font-size: 14px;
  color: #606365;
  line-height: 21px;
}

.logo_list {
  width: 1202px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #D5DAE5;
  border-right: 1px solid #D5DAE5;
}

.logo_list li {
  width: 200px;
  height: 92px;
  position: relative;
  overflow: hidden;
  display: flex;
  border-bottom: 1px solid #D5DAE5;
  border-left: 1px solid #D5DAE5;
}

.logo_list li div {
  width: 400px;
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  left: 0;
  top: 0;
  transition: left 0.5s;
}

.logo_list li:hover div {
  left: -200px;
}

.logo_list li div img {
  width: 200px;
  height: 92px;
}

/******** 首页 *********/

/******** 详情 *********/

/****** b2c *******/
.b2c_default_style {
  height: 220px;
  background-color: #F5F9FF;
  color: #222;
  font-size: 20px;
  display: flex;
  justify-content: center;
  justify-items: center;
  position: relative;
  cursor: pointer;
}

.b2c_default_style>div {
  width: 220px;
  padding: 55px 75px;
  text-align: center;
}

.b2c_default_style>div>span {
  padding-top: 5px;
}

.b2c_select {
  color: #fff;
  background: radial-gradient(circle closest-side, rgba(75, 165, 254, 1) 0%, rgba(75, 124, 254, 1) 100%);
}

.b2c_after:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 95px;
  border-width: 20px 0 20px 20px;
  border-style: solid;
  border-color: transparent #4B7CFE;
}

/****** b2c *******/
/****** b2b *******/
.av_item {
  width: 580px;
  height: 160px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  margin-top: 50px;
  cursor: pointer;
}

.av_item img {
  width: 40px;
  height: 40px;
}

.av_item:hover {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

/****** b2b *******/
/****** XiaoYuanBao *******/
.campus_item {
  width: 260px;
  height: 260px;
  background: url("../images/image_icon_bg.png") no-repeat left top;
}

.campus_item img {
  width: 100px;
  height: 100px;
}

/****** XiaoYuanBao *******/
/****** YiShiTang *******/
.yst_pc_menu {
  width: 260px;
  height: 270px;
  margin-top: 40px;
  background-color: #fff;
  padding: 40px 30px;
}

.yst_pc_menu img {
  width: 80px;
  height: 80px;
  margin-bottom: 25px;
}

.yst_mobile_tab {
  height: 60px;
  margin-bottom: 20px;
  font-size: 26px;
  color: #222;
  display: flex;
  cursor: pointer;
  font-weight: 500;
}

.yst_mobile_tab img {
  display: block;
  width: 44px;
  height: 44px;
  margin: 3px 20px 0 0;
}

.yst_mobile_tab span {
  line-height: 50px;
}

.yst_mobile_tab_select {
  font-size: 40px;
  color: #0EAA76;
}

.yst_mobile_tab_select .bor {
  border-bottom: 4px solid #0EAA76;
}

.yst .yst_btn,
.yst .yst_btn:hover,
.yst .yst_btn:focus,
.yst .yst_btn:active {
  width: 200px;
  height: 50px;
  line-height: 50px;
  border: 1px solid rgba(205, 219, 214, 1);
  border-radius: 25px;
  color: #222;
  font-size: 18px;
  padding: 0;
}

.yst .yst_btn_active,
.yst .yst_btn_active:hover,
.yst .yst_btn_active:focus,
.yst .yst_btn_active:active {
  background-color: #0EAA76;
  border-color: #0EAA76;
  color: #fff;
}

/****** YiShiTang *******/

/******** 详情 *********/

/****** 登录 *******/
.login_form {
  margin-top: 40px;
}

.login_form .form_item_50 {
  margin-bottom: 12px;
}

.login_form .form_item_50 .ant-form-item-label>label {
  width: 100%;
  font-size: 16px;
  color: #222;
}

.login_form button,
.login_form button:hover,
.login_form button:active,
.login_form button:focus {
  width: 80px;
  height: 50px;
  line-height: 50px;
  background: rgba(49, 109, 247, 1);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 0;
}

.form_item_50 .ant-input,
.form_item_50 .ant-input-affix-wrapper {
  height: 50px;
  line-height: 50px;
}

.form_item_50 .ant-input-affix-wrapper>.ant-input {
  height: 48px;
  line-height: 48px;
}

.form_item_50 a {
  color: #77838F;
  border-bottom: 1px solid #77838F
}

.form_item_50 a:hover {
  color: #316DF7;
  border-color: #316DF7;
}



/****** 登录 *******/
/****** 商户中心 *******/
.conItem {
  position: relative;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 274px;
  height: 447px;
  background: #FFFFFF;
  border-radius: 29px 29px 29px 29px;
  border: 1px solid #DDDDDD;
  opacity: 1;
  margin-right: 28px;
  margin-bottom: 20px;
}

.gm {
  width: 236px;
  height: 47px;
  background: #5B8EFF;
  border: #5B8EFF;
  border-radius: 13px 13px 13px 13px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conItem:nth-child(4n) {
  margin-right: 0;
}


.order {
  display: flex;
  justify-content: space-around;
  width: 1146px;
  margin: 0 auto;
  height: 140px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #E7F0F7;
}

.order2 {
  margin: 25px auto 0;
  width: 1146px;
  height: 140px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #E7F0F7;
}

.payB {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.js {
  margin-left: 23px;
  width: 169px;
  height: 47px;
  background: #316DF7;
  color: #fff;
  border: #316DF7;
  border-radius: 5px 5px 5px 5px;
}

.PayItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 57px;
  margin-right: 31px;
}

.oTop {

  border-bottom: 1px solid #F9FAFD;
  height: 55px;
  text-align: center;
  font-weight: bold;
  line-height: 55px;
}

.oBom {
  height: 84px;
  text-align: center;
  font-size: 14px;
  line-height: 84px;
}

.conBotton {
  padding-top: 36px;
  padding-left: 36px;
  width: 100%;
  height: 216px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  /* border: 1px solid #DDDDDD; */
}

.checked {

  border: 1px solid #289ACE;
}

.unChecked {

  border: 1px solid #DDDDDD;
}

.xz {
  position: absolute;
  bottom: 0;
  right: 0;
}

.line {
  margin-top: 6px;
  margin-bottom: 26px;
  width: 184px;
  height: 0px;
  opacity: 1;
  border: 1px solid #DDDDDD;
}

.pay_app {
  /* margin-right: 28px;
  margin-bottom: 28px; */
  /* position: relative; */
}

/* .pay_app:nth-child(4n) {
  margin-right: 0;
} */

.pay_app_item {
  margin: 0 auto 18px;

  position: relative;
  /* position: absolute;
  bottom: 30px; */
  /* left: 26px; */
  width: 1146px;
  height: 285px;
  border-radius: 16px 16px 16px 16px;
  border: 1px solid #DAEDFE;
  /* margin-bottom: 18px; */

  /* border-radius: 4px; */
  cursor: pointer;
}

.pay_app_b {
  /* width: 141px; */
  height: 29px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D5E1FB;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #B6CCFB;
  color: #307DE6;
}

.merchant_app_item {
  width: 1146px;
  height: 285px;
  border-radius: 16px 16px 16px 16px;
  border: 1px solid #DAEDFE;
  /* margin-bottom: 18px; */
  margin: 0 auto 18px;
  position: relative;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */

  /* padding: 45px 50px 30px; */
  /* 
  margin-right: 28px;
  margin-bottom: 28px; */

  cursor: pointer;
}

/* .merchant_app_item:nth-child(4n) {
  margin-right: 0;
} */

.img_div {
  position: relative;
}

.img_div img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.merchant_app_item:hover {
  border-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 19, 65, 0.1);
}

.merchant_app_item Button {
  width: 96px;
  height: 32px;
  border: 1px solid #D5DAE5;
}

.merchant_app_item:hover .hover,
.merchant_app_item:hover .hover:hover,
.merchant_app_item:hover .hover:active,
.merchant_app_item:hover .hover:focus {
  background-color: #316DF7;
  border-color: #316DF7;
  color: #fff;
}

.pay_app_item:hover {
  border-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 19, 65, 0.1);
}

.pay_app_item Button {
  width: 96px;
  height: 32px;
  border: 1px solid #D5DAE5;
}

.pay_app_item:hover .hover,
.pay_app_item:hover .hover:hover,
.pay_app_item:hover .hover:active,
.pay_app_item:hover .hover:focus {
  background-color: #316DF7;
  border-color: #316DF7;
  color: #fff;
}

/****** 商户中心 *******/
/****** 官网注册 *******/
.container {
  margin-bottom: 0 !important;
}

.container>.ant-form-item-control>.ant-form-item-control-input>.ant-form-item-control-input-content {
  display: flex;
}

.getCode {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  color: #316DF7;
  margin-left: 45px;
  cursor: pointer;
  margin-top: 33px;
  line-height: 50px;
}

.getCode2 {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  color: #316DF7;
  margin-left: 45px;
  cursor: pointer;
  line-height: 50px;
}

/****** 官网注册 *******/
/****** 产品注册 *******/
.reg_form {
  width: 540px;
  display: block;
  margin: 0 auto !important;
  padding: 60px 0 !important;
}

.form_item_40 {
  height: 40px;
  line-height: 40px;
  margin-bottom: 30px;
}

.form_item_40 .ant-form-item-label>label,
.form_item_label {
  width: 145px;
  padding: 0 0 0 25px;
  font-size: 14px;
  color: #77838F;
  height: 40px;
  line-height: 40px;
}

.form_item_40 .ant-form-item-control-input,
.form_item_40 .ant-input {
  height: 40px;
  line-height: 40px;
}

.form_item_40 .ant-select-selector,
.form_item_40 .ant-select-selection-search-input,
.form_item_40 .ant-input-affix-wrapper,
.form_item_40 .ant-select-selection-placeholder {
  height: 40px !important;
  line-height: 40px !important;
}

.form_item_40 .ant-input-affix-wrapper>input.ant-input {
  height: 38px;
}

.form_item_40 .ant-picker {
  padding: 0 11px;
  height: 40px;
  line-height: 40px;
}

.form_item_40 .ant-input[disabled] {
  color: #222;
}

.submit .reg_btn,
.submit .reg_btn:hover,
.submit .reg_btn:active,
.submit .reg_btn:focus {
  height: 40px;
  line-height: 40px;
  background: rgba(49, 109, 247, 1);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 0;
}

.submit .reg_btn_cancel,
.submit .reg_btn_cancel:hover,
.submit .reg_btn_cancel:active,
.submit .reg_btn_cancel:focus {
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  padding: 0;
  margin-right: 20px;
}

.form_item_40 .ant-form-item-control-input-content,
.form_item_40 .ant-upload-picture-card-wrapper,
.form_item_40 .ant-upload {
  height: 40px !important;
  border: none !important;
  margin: 0 !important;
}

.form_item_40 .ant-form-item-control-input-content {
  display: flex;
}

.form_item_40 .ant-upload-picture-card-wrapper {
  width: 110px !important;
}

.form_item_40 .ant-upload.ant-upload-select-picture-card>.ant-upload {
  padding: 0 !important;
  height: 40px !important;
  border: none !important;
  margin: 0 !important;
}

.removeImage {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  cursor: pointer;
}

.removeImage img {
  display: block;
}

.agreement a {
  color: #77838F;
}

.agreement a:hover {
  color: rgba(49, 109, 247, 1);
}

.afterText:after {
  content: "*至少上传1张，最多3张";
  position: absolute;
  top: 50px;
  left: 145px;
  color: #77838F;
}

/****** 产品注册 *******/
/****** antd *******/
#root .ant-spin {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: calc((100vh / 2) - 50px) 0;
  position: fixed !important;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  font-size: 18px;
  color: #fff;
}

#root .ant-spin-dot {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}

#root .ant-spin-dot-item {
  width: 20px;
  height: 20px;
  background-color: #fff;
}

.ant-spin-container::after {
  /* background-color: #000; */
  /* opacity: 0.3; */
}

.preview .ant-modal-body {
  padding: 0 !important;
  text-align: center;
}

.preview .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0;
}

.preview .ant-modal-content {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
}

.preview .ant-modal-confirm-btns {
  display: none;
}

.ant-modal-body .ant-btn.ant-btn-primary {
  background-color: rgba(49, 109, 247, 1) !important;
  color: #FFF !important;
}

.ant-modal-body .ant-btn {
  color: rgba(49, 109, 247, 1) !important;
  border-color: rgba(49, 109, 247, 1) !important;
}

.Carousel_item {
  width: 100% !important;
  height: 600px !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

.ant-carousel .slick-dots li {
  height: 5px !important;
}

.ant-carousel .slick-dots li button {
  height: 5px !important;
}

.ant-popover-content .ant-popover-arrow {
  display: none;
}

/****** antd *******/


.swiper-slide {
  text-align: center;
  transition: 300ms;
  transform: scale(0.7);
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}

.rotate_defalut {
  animation: turnAround 1s ease-in-out;
}

/* ------------ */
@keyframes turnAround {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}