@charset "utf-8";

* {
  margin: 0;
  padding: 0;
}

div p {
  margin: 0;
}

fieldset,
img,
span {
  border: 0;
  display: inline-block;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul,
li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

abbr,
acronym {
  border: 0;
}

a {
  cursor: pointer;
}

img {
  border: none;
}

body {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  font-family: "微软雅黑";
  background-color: #f5f5f5;
}

.current {
  background-color: #316DF7;
  color: #FFFFFF;
}

/*** padding ***/
.p-20 {
  padding: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-26 {
  padding-top: 26px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-25 {
  padding-left: 25px;
}

.l-105 {
  line-height: 105px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-38 {
  padding-top: 38px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-26 {
  padding-bottom: 26px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-100 {
  padding-bottom: 100px;
}

.p-30 {
  padding: 30px;
}

.p-10 {
  padding: 10px;
}

/*** padding ***/
/* margin上下 */
.mtb24 {
  margin: 24px 0;
}

/* margin上下 */

/* padding左右 */
.plr40 {
  padding: 0 40px;
}

.plr20 {
  padding: 0 20px;
}

/* padding左右 */

/* padding上下 */
.ptb40 {
  padding: 40px 0;
}

.ptb30 {
  padding: 30px 0;
}

.ptb20 {
  padding: 20px 0;
}

/* padding上下 */

/* padding上下左右 */

.ptrbl30-20 {
  padding: 30px 20px;
}

/*** margin ***/
.mar-20 {
  margin: 20px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-46 {
  margin-left: 46px;
}

.ml-10 {
  margin-left: 10px;
}

.mart-5 {
  margin-top: 5px;
}

.mart-50 {
  margin-top: 50px;
}

.mart-10 {
  margin-top: 10px;
}

.mart-18 {
  margin-top: 18px;
}

.mart-30 {
  margin-top: 30px;
}

.mart-40 {
  margin-top: 40px;
}

.mart-60 {
  margin-top: 60px;
}

.mart-70 {
  margin-top: 70px;
}

.mart-20 {
  margin-top: 20px;
}

.mart-80 {
  margin-top: 80px;
}

.mart-100 {
  margin-top: 100px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-26 {
  margin-right: 26px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-365 {
  margin-right: 365px;
}

.mar-auto {
  margin: 0 auto;
}

.mar-b-10 {
  margin-bottom: 10px;
}

.mar-b-15 {
  margin-bottom: 15px;
}

.mar-b-16 {
  margin-bottom: 16px;
}

.mar-b-20 {
  margin-bottom: 20px;
}

.mar-b-30 {
  margin-bottom: 30px;
}

.mar-b-40 {
  margin-bottom: 40px;
}

.mar-b-45 {
  margin-bottom: 45px;
}

/*** margin ***/

/*** border ***/
.bor-none {
  border: none
}

.bor-t {
  border-top: 1px solid #e5e5e5;
}

.bor-t-ffa729 {
  border-top: 1px solid #ffa729;
}

.bor-r {
  border-right: 1px solid #e5e5e5;
}

.bor-b-20 {
  border-bottom: 20px solid #f5f5f5;
}

.bor-t-20 {
  border-top: 20px solid #f5f5f5;
}

.bor-radius-8 {
  border-radius: 8px;
}

.bor-radius-050 {
  border-radius: 50%;
}

.bor-radius-10 {
  border-radius: 10px;
}

.bor-radius-20 {
  border-radius: 20px;
}

.bor-radius-30 {
  border-radius: 30px;
}

.bor-radius-5 {
  border-radius: 5px;
}

.bor-1px {
  border: 1px solid #a9a9a9;
}

.bor-1px-e7bcf1 {
  border: 1px solid #E7ECF1;
}

.bor-l-1px {
  border-left: 1px solid #e5e5e5;
}

.bor-r-1px {
  border-right: 1px solid #e5e5e5;
}

.bor-b-none {
  border-bottom: none !important;
}

.bor-b-1px {
  border-bottom: 1px solid #ccc;
}

/*** border ***/

/*** width ***/
.w025 {
  width: 25%;
}

.w03333 {
  width: 33.33333333333%;
}

.w080 {
  width: 80%;
}

.w060 {
  width: 60%;
}

.w050 {
  width: 50%;
}

.w040 {
  width: 40%;
}

.w030 {
  width: 30%;
}

.w0100 {
  width: 100%;
}

.w-60 {
  width: 60px;
}

.w-70 {
  width: 70px;
}

.w-100 {
  width: 100px;
}

.w-318 {
  width: 318px;
}

.w-120 {
  width: 120px;
}

.w-148 {
  width: 148px;
}

.w-162 {
  width: 162px;
}

.w-180 {
  width: 180px;
}

.w-200 {
  width: 200px;
}

.w-210 {
  width: 210px;
}

.w-220 {
  width: 220px;
}

.w-240 {
  width: 240px;
}

.w-250 {
  width: 250px;
}

.w-270 {
  width: 270px;
}

.w-284 {
  width: 284px
}

.w-290 {
  width: 290px
}

.w-300 {
  width: 300px;
}

.w-370 {
  width: 370px;
}

.w-380 {
  width: 380px;
}

.w-400 {
  width: 400px;
}

.w-420 {
  width: 420px;
}

.w-440 {
  width: 440px;
}

.w-470 {
  width: 470px;
}

.w-500 {
  width: 500px;
}

.w-540 {
  width: 540px;
}

.w-550 {
  width: 550px;
}

.w-410 {
  width: 412px;
}

.w-480 {
  width: 480px;
}

.w-570 {
  width: 570px;
}

.w-630 {
  width: 630px;
}

.w-690 {
  width: 690px;
}

.w-720 {
  width: 720px;
}

.w-730 {
  width: 730px;
}

.w-860 {
  width: 860px;
}

.w-900 {
  width: 900px;
}

.w-1200 {
  width: 1200px;
}

.w-38 {
  width: 38px;
}

.w-80 {
  width: 80px;
}

.w-22 {
  width: 22px;
}

.w-32 {
  width: 32px;
}

.w-34 {
  width: 34px;
}

.w-50 {
  width: 50px;
}

.w-52 {
  width: 52px;
}

.w-280 {
  width: 280px;
}

/*** width ***/

/* width and hight */
.wh280 {
  width: 280px;
  height: 280px;
}

.wh50 {
  width: 50px;
  height: 50px;
}

.wh180 {
  width: 180px;
  height: 180px;
}

.wh20 {
  width: 20px;
  height: 20px;
}

.wh60 {
  width: 60px;
  height: 60px;
}

.wh40 {
  width: 40px;
  height: 40px;
}

.wh10 {
  width: 10px;
  height: 10px;
}

.wh12 {
  width: 12px;
  height: 12px;
}

.wh100 {
  width: 100px;
  height: 100px;
}

.wh176 {
  width: 176px;
  height: 100px;
}

.wh32-58 {
  width: 32px;
  height: 58px;
}

.wh28-13 {
  width: 28px;
  height: 13px;
}

.wh32 {
  width: 32px;
  height: 32px;
}

.wh380 {
  width: 380px;
  height: 364px;
}

.wh250-138 {
  width: 250px;
  height: 138px;
}

.wh180-200 {
  width: 180px;
  height: 200px;
}

.wh380-400 {
  width: 380px;
  height: 400px;
}

.wh168-300 {
  width: 168px;
  height: 300px;
}

.wh270-230 {
  width: 270px;
  height: 230px;
}

.wh224-302 {
  width: 224px;
  height: 302px;
}

.wh742-346 {
  width: 742px;
  height: 346px;
}

.wh262-178 {
  width: 262px;
  height: 178px;
}

.wh160-42 {
  width: 160px;
  height: 42px;
}

.wh100p-70 {
  width: 100%;
  height: 70px;
}

.wh170-128 {
  width: 170px;
  height: 128px;
}

/* width and hight */

/*** height ***/
.hl-32 {
  height: 32px;
  line-height: 32px;
}

.hl-36 {
  height: 36px;
  line-height: 36px;
}

.hl-38 {
  height: 38px;
  line-height: 38px;
}

.hl-44 {
  height: 44px;
  line-height: 44px;
}

.hl-50 {
  height: 50px;
  line-height: 50px;
}

.hl-80 {
  height: 80px;
  line-height: 80px;
}

.hl-100 {
  height: 100px;
  line-height: 100px;
}

.h-80 {
  height: 80px;
}

.h-8 {
  height: 8px;
}

.h-128 {
  height: 128px;
}

.h-140 {
  height: 140px;
}

.h-160 {
  height: 160px;
}

.h-180 {
  height: 180px;
}

.h-200 {
  height: 200px;
}

.h-210 {
  height: 210px;
}

.h-240 {
  height: 240px;
}

.h-300 {
  height: 300px;
}

.h-30 {
  height: 30px;
}

.hl-60 {
  height: 60px;
  line-height: 60px;
}

.h-22 {
  height: 22px;
}

.h-32 {
  height: 32px;
}

.h-34 {
  height: 34px;
}

.h-40 {
  height: 40px;
}

.h-50 {
  height: 50px;
}

.h-52 {
  height: 52px;
}

.h-60 {
  height: 60px;
}

.hl-52 {
  height: 52px;
  line-height: 52px;
}

.hl-70 {
  height: 70px;
  line-height: 70px;
}

.height-100 {
  height: 100%;
}

.hl-99 {
  height: 99px;
  line-height: 99px;
}

.hl-98 {
  height: 98px;
  line-height: 98px;
}

.hl-90 {
  height: 90px;
  line-height: 90px;
}

.hl-82 {
  height: 82px;
  line-height: 82px;
}

.hl-200 {
  height: 200px;
  line-height: 200px;
}

.h-400 {
  height: 400px;
}

.h-480 {
  height: 480px;
}

.h-500 {
  height: 500px;
}

.h-700 {
  height: 700px;
}

.h-664 {
  height: 664px;
}

/*** height ***/

/*** font ***/
.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-30 {
  font-size: 30px;
}

.f-28 {
  font-size: 28px;
}

.f-26 {
  font-size: 26px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-40 {
  font-size: 40px;
}

.f-44 {
  font-size: 44px;
}

.f-50 {
  font-size: 50px;
}

.f-54 {
  font-size: 54px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-decoration {
  text-decoration: line-through;
}

.t-indent-60 {
  text-indent: 60px;
}

.f-bold {
  font-weight: bold;
}

.t-indent-20 {
  text-indent: 20px;
}

/*** font ***/
/*** color ***/
.c-ffa729 {
  color: #ffa729;
}

.c-545556 {
  color: #545556;
}

.c-ea5413 {
  color: #EA5413;
}

.c-ff6c76 {
  color: #ff6c76;
}

.c-ff668f {
  color: #ff668f;
}

.c-fe9e38 {
  color: #fe9e38;
}

.c-3e6bb3 {
  color: #3e6bb3;
}

.c-2A63DF {
  color: #2A63DF;
}

.c-77838F {
  color: #77838F;
}

.c-00BC44 {
  color: #00BC44;
}

.c-0D1A26 {
  color: #0D1A26;
}

.c-c0c0c0 {
  color: #c0c0c0;
}

.c-ff789c {
  color: #ff789c;
}

.c-606365 {
  color: #606365;
}

.c-ff4c4c {
  color: #ff4c4c;
}

.c-6593ee {
  color: #6593EE;
}

.c-aaadb1 {
  color: #AAADB1;
}

.c-80bfff {
  color: #80bfff;
}

.c-757575 {
  color: #757575;
}

.c-ccc {
  color: #ccc;
}

.c-222 {
  color: #222;
}

.c-333 {
  color: #333333;
}

.c-3c3c3c {
  color: #3c3c3c;
}

.c-666 {
  color: #666666;
}

.c-999 {
  color: #999999;
}



.c-888 {
  color: #888888;
}

.c-fff {
  color: #ffffff;
}

.c-fe8c25 {
  color: #fe8c25;
}

.c-3B97EA {
  color: #3B97EA;
}

.c-316DF7 {
  color: #316DF7;
  ;
}

.c-30E103D {
  color: #0E103D;
}

/*** color ***/
/*** background-color ***/
.bg-f5f5f5 {
  background-color: #f5f5f5;
}

.bg-F3F6FA {
  background-color: #F3F6FA;
}

.bg-F9FAFF {
  background-color: #F9FAFF;
}

.bg-F58A06 {
  background-color: #F58A06;
}

.bg-FFBA28 {
  background-color: #FFBA28;
}

.bg-CED4D9 {
  background-color: #CED4D9;
}

.bg-666 {
  background-color: #666666;
}

.bg-316DF7 {
  background-color: #316DF7;
}

.bg-ffa729 {
  background-color: #ffa729;
}

.bg-EE0200 {
  background-color: #EE0200;
}

.bg-3856df {
  background-color: #3856df;
}

.bg-3e6bb3 {
  background-color: #3E6BB3;
}

.bg-3B97EA {
  background-color: #3B97EA;
}

.bg-6593ee {
  background-color: #6593EE;
}

.bg-1fc397 {
  background-color: #1fc397;
}

.bg-00c4c2 {
  background-color: #00C4C2;
}

.bg-f1f2f7 {
  background-color: #f1f2f7;
}

.bg-fe9e38 {
  background-color: #fe9e38;
}

.bg-F0C483 {
  background-color: #F0C483;
}

.bg-57c8f2 {
  background-color: #57C8F2;
}

.bg-fa9533 {
  background-color: #FA9533;
}

.bg-ff6c60 {
  background-color: #FF6C60;
}

.bg-fff {
  background-color: #ffffff;
}

.bg-F5F6FA {
  background-color: #F5F6FA;
}

.bg-EEF3FF {
  background-color: #EEF3FF;
}

.bg-0E103D {
  background-color: #0E103D;
}

.bg-f8f8f8 {
  background-color: #f8f8f8;
}

.bg-36404C {
  background-color: #36404C
}

.RegBox .bg-c-ffa729 {
  background-color: #ffa729;
  border: 2px solid #ffa729;
  color: #ffffff;
}

.bg-ff789c {
  background-color: #ff789c;
}

.bg-linear {
  background: linear-gradient(90deg, #FFFFFF 0%, #EBF0F8 100%);
  opacity: 1;
}

/*** background-color ***/
/**border**/
.bor-fa729 {
  border: 1px solid #ffa729;
}

.bor-fa729-2 {
  border: 2px solid #ffa729;
}

.bor-1px-3e6bb3 {
  border: 1px solid #3e6bb3;
}

.bor-b-2-EA5413 {
  border-bottom: 2px solid #EA5413;
  ;
}

.bor-t-dashed {
  border-top: 2px dashed #e5e5e5;
}

.bor-l-5px-00C4C2 {
  border-left: 5px solid #00c4c2;
}

/**border**/
/*** flex ***/
.space-between {
  display: flex;
  justify-content: space-between;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.a-center {
  align-items: center;
}

.j-center {
  justify-content: center;
}

.f-sb {
  justify-content: space-between;
}

.a-end {
  align-items: flex-end;
}

.a-start {
  align-items: flex-start;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cloumn {
  flex-direction: column;
}

.f-center-cloumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.display_none {
  display: none;
}

.display_inline_block {
  display: inline-block;
}

/*** flex ***/
/* box-shadow */
.box-shadow {
  box-shadow: 0px 8px 24px rgba(5, 28, 85, 0.1);
}

/* box-shadow */

/*** position ***/
.pos-relative {
  position: relative;
}

.pos-center {
  position: absolute;
  top: 300px;
  left: 60px;
}

.pos-center-100 {
  position: absolute;
  top: 150px;
  left: 60px;
}

.pos-center-80 {
  position: absolute;
  top: 80px;
  left: 30px;
}

.pos-center-cancel {
  position: absolute;
  top: 400px;
  left: 95px;
}

.pos-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
}

.pos-relative {
  position: relative;
}

.pos-repeat {
  position: absolute;
  left: 0;
  top: 0;
}

.pos-fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.topBg {
  width: 100%;
  height: 75px;
  line-height: 75px;
  padding-left: 10px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  background: #2996FC;
}

.pos-absolute-bottom27 {
  position: absolute;
  bottom: 27px;
  left: 16px;
  width: 145px;
}

.pos-absolute-custom01 {
  position: absolute;
  top: 0;
  left: 60%;
}

.pos-absolute-custom02 {
  position: absolute;
  top: 40%;
  left: 76%;
  width: 242px;
}

.pos-absolute-custom03 {
  position: absolute;
  bottom: 0;
  right: 30%;
}

.pos-absolute-custom04 {
  position: absolute;
  bottom: 15%;
  width: 242px;
  left: 10%;
}

.pos-absolute-custom05 {
  position: absolute;
  top: 16%;
  left: 14%;
}

.pos-center-zindex {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 333;
}

.pos-mask {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 222;
  background-color: #000;
}

/*** position ***/
/*** display ***/
.display-inline-block {
  display: inline-block;
}

/*** z-index ****/

.z-index {
  z-index: 109;
}

.z-index-99 {
  z-index: 99;
}

.text-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bg-EEF3FF {
  background-color: #EEF3FF;
}